// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Integrationwithexistinghrmsplatform from "../../blocks/integrationwithexistinghrmsplatform/src/Integrationwithexistinghrmsplatform";
import Statusofinvoices from "../../blocks/statusofinvoices/src/Statusofinvoices";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfcustomizedvisualanalytics4 from "../../blocks/cfcustomizedvisualanalytics4/src/Cfcustomizedvisualanalytics4";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfworkspacemappingandleavereport3 from "../../blocks/cfworkspacemappingandleavereport3/src/Cfworkspacemappingandleavereport3";
import Timestamp from "../../blocks/timestamp/src/Timestamp";
import Cfautosuggestionautosave5 from "../../blocks/cfautosuggestionautosave5/src/Cfautosuggestionautosave5";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Cfchecklistdefinition4 from "../../blocks/cfchecklistdefinition4/src/Cfchecklistdefinition4";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cffieldvaluesbasedflowdefinition2 from "../../blocks/cffieldvaluesbasedflowdefinition2/src/Cffieldvaluesbasedflowdefinition2";
import Cfcustomizablefilterconditions4 from "../../blocks/cfcustomizablefilterconditions4/src/Cfcustomizablefilterconditions4";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Cftasktrackmanagementboard from "../../blocks/cftasktrackmanagementboard/src/Cftasktrackmanagementboard";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import Pagination from "../../blocks/pagination/src/Pagination";
import Cfcolorcodeautomation4 from "../../blocks/cfcolorcodeautomation4/src/Cfcolorcodeautomation4";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Editinvoices from "../../blocks/editinvoices/src/Editinvoices";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfadditionalrolesupport5 from "../../blocks/cfadditionalrolesupport5/src/Cfadditionalrolesupport5";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";



const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Integrationwithexistinghrmsplatform:{
 component:Integrationwithexistinghrmsplatform,
path:"/Integrationwithexistinghrmsplatform"},
Statusofinvoices:{
 component:Statusofinvoices,
path:"/Statusofinvoices"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfcustomizedvisualanalytics4:{
 component:Cfcustomizedvisualanalytics4,
path:"/Cfcustomizedvisualanalytics4"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Cfworkspacemappingandleavereport3:{
 component:Cfworkspacemappingandleavereport3,
path:"/Cfworkspacemappingandleavereport3"},
Timestamp:{
 component:Timestamp,
path:"/Timestamp"},
Cfautosuggestionautosave5:{
 component:Cfautosuggestionautosave5,
path:"/Cfautosuggestionautosave5"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Cfchecklistdefinition4:{
 component:Cfchecklistdefinition4,
path:"/Cfchecklistdefinition4"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cffieldvaluesbasedflowdefinition2:{
 component:Cffieldvaluesbasedflowdefinition2,
path:"/Cffieldvaluesbasedflowdefinition2"},
Cfcustomizablefilterconditions4:{
 component:Cfcustomizablefilterconditions4,
path:"/Cfcustomizablefilterconditions4"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Cftasktrackmanagementboard:{
 component:Cftasktrackmanagementboard,
path:"/Cftasktrackmanagementboard"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Pagination:{
 component:Pagination,
path:"/Pagination"},
Cfcolorcodeautomation4:{
 component:Cfcolorcodeautomation4,
path:"/Cfcolorcodeautomation4"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Editinvoices:{
 component:Editinvoices,
path:"/Editinvoices"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfadditionalrolesupport5:{
 component:Cfadditionalrolesupport5,
path:"/Cfadditionalrolesupport5"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;